import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Image Settings // The Wide Dynamic Range Mode",
  "path": "/Web_User_Interface/1080p_Series/Multimedia/Image/Wide_Dynamic_Range/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Multimedia_Image.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is a test of the camera´s Wide Dynamic Range settings. The following image settings have been used for the following test runs:`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      